var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.field === 'payStatus')?[(_vm.status === '已支付')?[_c('span',{staticStyle:{"color":"#5B83FD"}},[_vm._v("已支付")])]:(
			_vm.status === '退款中' ||
			_vm.status === '退款审核中' ||
			_vm.status === '待支付' ||
			_vm.status === '预订'
		)?[_c('span',{staticStyle:{"color":"#F08E05"}},[_vm._v(_vm._s(_vm.status))])]:(
				_vm.status === '退款已拒绝' ||
				_vm.status === '无效订单' ||
				_vm.status === '已退款' ||
				_vm.status === '已取消'
		)?[_c('span',{staticStyle:{"color":"#FF4949"}},[_vm._v(_vm._s(_vm.status))])]:[_vm._v(" "+_vm._s(_vm.status)+" ")]]:(_vm.field === 'orderStatus')?[(_vm.status === '已完成')?[_c('span',{staticStyle:{"color":"#11B211"}},[_vm._v(_vm._s(_vm.status))])]:_vm._e(),(_vm.status === '待退货' || _vm.status === '待处理')?[_c('span',{staticStyle:{"color":"#5B83FD"}},[_vm._v(_vm._s(_vm.status))])]:(_vm.status === '已拒绝')?[_c('span',{staticStyle:{"color":"#FF4949"}},[_vm._v(_vm._s(_vm.status))])]:_vm._e(),[_c('span',[_vm._v(_vm._s(_vm.status))])]]:(_vm.field === 'offlineOrderStatus')?[(_vm.status === '已发起' || _vm.status === '已下发')?[_c('span',{staticStyle:{"color":"#5B83FD"}},[_vm._v(_vm._s(_vm.status))])]:(_vm.status === '未发起')?[_c('span',{staticStyle:{"color":"#F25757"}},[_vm._v(_vm._s(_vm.status))])]:_vm._e(),[_c('span',[_vm._v(_vm._s(_vm.status))])]]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }