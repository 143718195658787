<template>
	<div>
		<template v-if="field === 'payStatus'">
			<template v-if="status === '已支付'">
				<span style="color: #5B83FD">已支付</span>
			</template>
			<template v-else-if="
				status === '退款中' ||
				status === '退款审核中' ||
				status === '待支付' ||
				status === '预订'
			">
				<span style="color: #F08E05">{{ status }}</span>
			</template>
			<template
					v-else-if="
					status === '退款已拒绝' ||
					status === '无效订单' ||
					status === '已退款' ||
					status === '已取消'
			">
				<span style="color: #FF4949">{{ status }}</span>
			</template>
			<template v-else>
				{{ status }}
			</template>
		</template>
		<template v-else-if="field === 'orderStatus'">
			<template v-if="status === '已完成'">
				<span style="color: #11B211">{{ status }}</span>
			</template>
			<template v-if="status === '待退货' || status === '待处理'">
				<span style="color: #5B83FD">{{ status }}</span>
			</template>
			<template v-else-if="status === '已拒绝'">
				<span style="color: #FF4949">{{ status }}</span>
			</template>
			<template>
				<span>{{ status }}</span>
			</template>
		</template>
		<template v-else-if="field === 'offlineOrderStatus'">
			<template v-if="status === '已发起' || status === '已下发'">
				<span style="color: #5B83FD">{{ status }}</span>
			</template>
			<template v-else-if="status === '未发起'">
				<span style="color: #F25757">{{ status }}</span>
			</template>
			<template>
				<span>{{ status }}</span>
			</template>
		</template>
	</div>
</template>

<script>
export default {
	name: "formatter",
	props: {
		status: {
			type: String,
			default: () => ''
		},
		field: {
			type: String,
			default: ''
		}
	}
}
</script>

<style scoped>

</style>
