<template>
	<div class="cToolbar" style="position: relative">
		<div style="display: inline-block;" :style="{width: hasExportFile? 'calc(100% - 80px)': ''}">
			<div style="display: inline-block;">
				<div class="d-flex align-content-space-between flex-wrap">
					<template v-for="(config, index) in fixedConfig">
						<template v-if="config.type === 'input'">
							<v-text-field
									autocomplete="off"
									dense
									:style="{width: config.width}"
									class="mr-2"
									:label="config.label"
									v-model="form[config.property]"
									:key="'fixed_' + index">
							</v-text-field>
						</template>
						<template v-else-if="config.type === 'select'">
							<w-autocomplete
									dense
									class="mr-2"
									:key="'fixed_' + index"
									:multiple="config.multiple"
									:items="config.items"
									:label="config.label"
									:value="calculateDefaultValue(config, 0)"
									:arrayType="config.arrayType"
									@change="form[config.property] = $event"
							></w-autocomplete>
						</template>
					</template>
					<template v-if="showOthersSearch">
						<template v-for="(config, index) in othersConfig">
							<template v-if="config.type === 'input'">
								<v-text-field
										autocomplete="off"
										dense
										:style="{width: config.width}"
										class="mr-2"
										:label="config.label"
										v-model="form1[config.property]"
										:key="'others_' + index">
								</v-text-field>
							</template>
							<template v-else-if="config.type === 'select'">
								<w-autocomplete
										dense
										class="mr-2"
										:key="index"
										:arrayType="config.arrayType"
										:multiple="config.multiple"
										:items="config.items"
										:label="config.label"
										:value="calculateDefaultValue(config, 1)"
										@change="form1[config.property] = $event"
								></w-autocomplete>
							</template>
							<template v-else-if="config.type === 'dateTime'">
								<single-time
										v-model="form1[config.property]"
										dense
										:hideDetails="true"
										clazz="mr-2"
										:label="config.label"
										:key="index"
										nudgeTop="12px"
										:clearable="true"
								>
								</single-time>
							</template>
							<template v-else-if="config.type === 'rangeTime'">
								<range-time
										@change="form1[config.property1] = $event.split(' ~ ')[0]; form1[config.property2] = $event.split(' ~ ')[1]"
										dense
										:hideDetails="true"
										clazz="mr-4"
										:label="config.label"
										:key="index"
										nudgeTop="12px"
										:clearable="true"
								>
								</range-time>
							</template>
						</template>
					</template>
					<v-btn depressed color="primary" @click="search" style="margin-right: 10px">
						<v-icon size="16" class="iconfont mr-1">iconfont icon-sousuo</v-icon>
						查询
					</v-btn>
					<!--					<v-btn depressed color="primary" @click="advancedSearch">-->
					<!--						{{ searchText }}-->
					<!--					</v-btn>-->
				</div>
			</div>
		</div>
		<template v-if="hasExportFile">
			<div style="width: 80px; display: inline-flex; position: absolute; right: 0; bottom: 0">
				<v-btn depressed color="primary" @click="exportFile" :loading="exportFileLoading">
					导出
				</v-btn>
			</div>
		</template>
	</div>
</template>

<script>
import singleTime from "@/components/dateTime/singleTime.vue";
import rangeTime from "@/components/dateTime/rangeTime.vue";
import wAutocomplete from '@/components/w-autocomplete/w-autocomplete.vue';

export default {
	name: "advancedSearch",
	components: {singleTime, wAutocomplete, rangeTime},
	methods: {
		getSearchForm() {
			let obj = this.showOthersSearch ? Object.assign({}, this.form, this.form1) : this.form;
			let o = {};
			for (const key in obj) {
				if (obj[key]) {
					o[key] = obj[key];
				}
			}
			return o
		},
		search() {
			const o = this.getSearchForm()
			this.$emit("search", o);
		},
		advancedSearch() {
			this.showOthersSearch = !this.showOthersSearch;
			this.searchText = this.showOthersSearch ? '收起' : '高级检索';
			this.form1 = {};
			this.$emit('advancedSearch');
		},
		exportFile() {
			this.exportFileLoading = true
			const o = this.getSearchForm()
			this.$emit('exportFile', o, () => {
				this.exportFileLoading = false
			})
		}
	},
	props: {
		// 固定搜索
		fixedConfig: {
			type: Array,
			default: () => []
		},
		// 其他搜索
		othersConfig: {
			type: Array,
			default: () => []
		},
		// 传递的额外参数
		extraParams: {
			type: String,
			default: ''
		},
		hasExportFile: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			searchText: '高级检索',
			form: {},
			form1: {},
			showOthersSearch: true,
			exportFileLoading: false
		}
	},
	mounted() {
		if (this.extraParams) {
			const array = this.extraParams.split('&');
			if (array[2] === '1') {
				this.form1[array[0]] = array[1];
				this.searchText = '收起';
				this.showOthersSearch = true;
				// 重新计算表格高度
				this.$emit('advancedSearch');
			} else {
				this.form[array[0]] = array[1];
			}
			this.search();
		}
		window.setTimeout(() => {
			// 重新计算表格高度
			this.$emit('advancedSearch');
		}, 300)
	},
	computed: {
		calculateDefaultValue() {
			return function (config, sign) {
				const value = sign === 0 ? this.form[config.property] : this.form1[config.property]
				if (config.multiple && !config.arrayType) {
					return value ? (Array.isArray(value) ? value : [value]) : []
				}
				return value
			}
		}
	}
}
</script>

<style scoped lang="scss">

::v-deep {

	.v-text-field input {
		font-size: 14px;
	}

	.mdi-chevron-down::before {
		color: #DEE2EE;
		font-size: 20px;
	}

	.v-application .d-flex {
		display: -webkit-box;
	}

	.v-input {
		flex: none;
		width: 190px;
	}

	.v-btn {
		margin-bottom: 13px;
	}

	.v-input--dense > .v-input__control > .v-input__slot {
		margin-bottom: 0;
	}
}

.mr-2 {
	width: 190px !important;
}

.cToolbar {
	padding: 20px 20px 0 20px;
	background-color: #ffffff;
	border-radius: 4px;
	margin-bottom: 10px;
}
</style>
