<template>
	<v-text-field
			:label="label"
			:value="value"
			:readonly="readonly"
	>
		<template v-slot:append-outer>
			<v-tooltip :open-delay="300" bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-icon @click="iconAction" v-on="on" v-bind="attrs" class="icon iconfont">iconfont {{ icon }}</v-icon>
				</template>
				<span>{{ tooltip }}</span>
			</v-tooltip>
		</template>
	</v-text-field>
</template>

<script>
export default {
	name: "c-text-field",
	props: {
		label: {
			type: String,
			default: ''
		},
		value: {
			type: [String, Number, Object],
			default: ''
		},
		icon: {
			type: String,
			default: ''
		},
		tooltip: {
			type: String,
			default: ''
		},
		readonly: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		iconAction() {
			this.$emit('iconAction')
		}
	}
}
</script>

<style scoped lang="scss">
::v-deep {
	.v-input__append-outer {
		position: absolute;
		right: 0;
	}

	.icon-down:before, .icon-up:before {
		color: var(--v-primary-base);
	}
}
</style>
