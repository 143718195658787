import cTextField from '../c-text-field.vue';

export default {
	data() {
		return {
			hideField: true
		}
	},
	methods: {
		setFormItems(response, fields, props, _index, texts, num) {
			let contents = [];
			response.forEach((item, row) => {
				let index = row + 1;
				contents.push(
					{
						cols: 4,
						subassembly: "VTextField",
						property: fields[0] + index,
						connector: {
							props: {
								label: this.replaceText(texts[0], index),
								readonly: true
							},
							style: {
								display: index > num ? 'none' : ''
							}
						},
						hideField: index,
						default: item[fields[0]]
					},
					{
						cols: 4,
						subassembly: "VTextField",
						property: fields[1] + index,
						connector: {
							props: {
								label: this.replaceText(texts[1], index),
								readonly: true
							},
							style: {
								display: index > num ? 'none' : ''
							}
						},
						hideField: index,
						default: item[fields[1]]
					},
					{
						cols: 4,
						subassembly: (response.length > num && index === num) ? cTextField : "VTextField",
						property: fields[2] + index,
						connector: {
							props: {
								label: this.replaceText(texts[2], index),
								'icon': (response.length > num && index === num) ? 'icon-a-zhankaianniudingdan' : '',
								tooltip: '展开剩余内容',
								color: 'var(--v-primary-base)',
								readonly: true
							},
							style: {
								display: index > num ? 'none' : ''
							},
							on: {
								'iconAction': () => {
									this.hideField = !this.hideField;
									props.deploy.row.forEach((item) => {
										if (item.hideField > num) {
											item.connector.style.display = this.hideField ? 'none' : '';
										} else if (item.hideField === num && item.property.includes(fields[2])) {
											item.connector.props['icon'] = this.hideField ? 'icon-a-zhankaianniudingdan' : 'icon-a-shouqianniudingdan';
											item.connector.props['tooltip'] = this.hideField ? '展开剩余内容' : '收起剩余内容';
										}
									});
								}
							}
						},
						hideField: index,
						default: item[fields[2]]
					}
				);
			});
			contents.unshift(_index, 0);
			Array.prototype.splice.apply(props.deploy.row, contents);
		},
		replaceText(text, index) {
			return text.replace('*', index);
		}
	}
}
